import React, { useMemo, useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import { graphql } from 'gatsby'
import { Box } from '@mui/material'
import { Header, Footer } from '@/components'
import {
  ItaContent,
  AusContent,
  enContent,
  plContent,
  NZLcontent,
} from '@/components/legalNoticeConfig/config'
import { getUrlParam, transCountry } from '@/components/PrivacyPolicy/config'

const LegalNotice = (props) => {
  const [country, setCountry] = useState<string | undefined>('')
  const [lang, setLang] = useState<string | undefined>('')

  const showContent = useMemo(() => {
    let t = enContent
    if (country === 'Italy' && lang === 'it_IT') {
      t = ItaContent
    }
    if (country === 'Australia') {
      t = AusContent
    }
    if (country === 'Poland') {
      t = plContent
    }
    if (country === 'New Zealand') {
      t = NZLcontent
    }
    return t
  }, [country, lang])

  useEffect(() => {
    const urlCountry =
      (getUrlParam('country') && transCountry[getUrlParam('country')]) || Cookies.get('COUNTRY')
    const urlLang = getUrlParam('language') || Cookies.get('EXPRESS_LANG')
    setCountry(urlCountry)
    setLang(urlLang)
  }, [])

  return (
    <>
      <Header
        location={props.location}
        headerLocale={JSON.parse(props.data.headerLocale.data)}
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
      <Box
        sx={{
          pt: '90px',
          padding: '90px 24px 60px',
          h1: {
            textAlign: 'center',
            p: '30px',
          },
          div: {
            m: '20px 0',
          },
        }}
      >
        <h1>{showContent?.title}</h1>
        {showContent?.content?.map((item) => {
          return <div>{item}</div>
        })}
      </Box>
      <Footer
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
    </>
  )
}

export default LegalNotice

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["LegalNotice"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
        }
      }
    }
    commonLocale: locale(ns: { eq: "common" }, language: { eq: $language }) {
      data
    }
    headerLocale: locale(ns: { eq: "header" }, language: { eq: $language }) {
      data
    }
    footerLocale: locale(ns: { eq: "footer" }, language: { eq: $language }) {
      data
    }
    languageLocale: locale(ns: { eq: "language" }, language: { eq: $language }) {
      data
    }
    seoLocale: locale(ns: { eq: "seo" }, language: { eq: $language }) {
      data
    }
  }
`
